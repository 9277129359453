import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { AppRoute } from "../types";

const LazyLoad = (path: string) => {
  const Component = lazy(() => import(`../pages${path}`));
  return (
    <Suspense fallback={<div>Loding...</div>}>
      <Component />
    </Suspense>
  );
};

const routes: AppRoute[] = [
  {
    path: "/",
    element: LazyLoad("/Home"),
  },
  {
    path: "/terms",
    element: LazyLoad("/Terms"),
  },
  {
    path: "/policy",
    element: LazyLoad("/Policy"),
  },
  {
    path: "*",
    element: LazyLoad("/NotFound"),
  },
];

const router = createBrowserRouter(routes);

export default router;
